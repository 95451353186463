import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { CameraControler } from "./camera-controls.js";

CameraControler.install({ THREE: THREE });

export class Setup {
    constructor(){

        
        // DISABLE RIGHT CLICK

        document.addEventListener('contextmenu', event => event.preventDefault(), false);

        // SCENE SETUP

        var scene = new THREE.Scene({ antialias: true });
        // scene.background = new THREE.Color(0xDAD3FF);
        scene.background = new THREE.Color(0xFFFFFF);
        // CAMERA SETUP

       

        var camera = new THREE.PerspectiveCamera(39, window.innerWidth / window.innerHeight, 0.25, 2000);
        camera.position.set(0,0,8);

        camera.baseFOV = 26;

        // RENDERER SETUP
        var targetCanvas = document.querySelector(".webgl");
        var renderer = new THREE.WebGLRenderer({canvas: targetCanvas,antialias: true});

        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );

        // MOUSE SETUP

        var mouse = new THREE.Vector2();

        // CAMERA CONTROL SETUP

        const cameraControler = new CameraControler(camera, targetCanvas);

        // cameraControler.maxPolarAngle = 1.6;
        // cameraControler.minPolarAngle = 0.;

        cameraControler.setPosition(2, 5., -8., false);
        cameraControler.setTarget(0, 1.7, 0, false);
        // cameraControler.setTarget(0, 0.7, 0, false);

        // cameraControler.rotateAzimuthTo(2 * 2.9, false);
        // cameraControler.rotatePolarTo(1.545, false);


        // cameraControler.mouseButtons.right = CameraControler.ACTION.NONE;


        cameraControler.dampingFactor = .005;
        cameraControler.draggingDampingFactor = .005;
        cameraControler.distance = 10.;

        var camera_freedom = 0.6;

        cameraControler.minAzimuthAngle = cameraControler.azimuthAngle - camera_freedom*2.;
        cameraControler.maxAzimuthAngle = cameraControler.azimuthAngle + camera_freedom*2.;
        cameraControler.minPolarAngle = cameraControler.polarAngle - camera_freedom;
        cameraControler.maxPolarAngle = cameraControler.polarAngle + camera_freedom;
        cameraControler.maxDistance = 10.;
        cameraControler.minDistance = 10.;

        this.cameraControler = cameraControler;

        //ORBIT CONTROL SETUP
        console.log(cameraControler.polarAngle,cameraControler.azimuthAngle);

        // const controls = new OrbitControls(camera, renderer.domElement);
        // controls.update();

        //replace this...

        // Add to instance

        var onResize = [];

        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.mouse = mouse;
        this.onResize = onResize;

        function updateCameraFOV() {

            if (camera.aspect < 1) {
                var vFOVC = camera.baseFOV * Math.PI / 180;
                var vFOVC2 = 2. * Math.atan(Math.tan(vFOVC / 2.) / camera.aspect);
                camera.fov = vFOVC2 * 180. / Math.PI;
                console.log(camera.fov);
            } else {
                camera.fov = camera.baseFOV;
            }
        }

        // RESIZE

        window.addEventListener('resize', onWindowResize, false);

        function onWindowResize() {
            var width = window.innerWidth;
            var height = window.innerHeight;
            camera.aspect = width / height;
            updateCameraFOV();
            onResize.forEach((f)=>{
                f(width,height);
            });
            // update resolution for material uniforms.
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        }

        onWindowResize();
    }
    
}

var setup = new Setup();

function getWorldDimensions(depth = 8){
    var vFOVC = setup.camera.fov * Math.PI / 180;
    var h = 2 * Math.tan(vFOVC / 2) * (depth);
    var w = h * setup.camera.aspect;
    return {w:w,h:h};
}

export {setup, getWorldDimensions}











