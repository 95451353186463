import * as THREE from 'three'

import { setup } from "./setup"

var {camera} = setup;

const listener = new THREE.AudioListener();
camera.add(listener);

// create a global audio source
export const sound = new THREE.Audio(listener);

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load('254378__eevan__energy-shield.mp3', function (buffer) {
    sound.setBuffer(buffer);
    sound.setLoop(true);
    sound.setVolume(2.);
    sound.playbackRate = 0.5;
    // sound.play();
});

console.log(sound.context);

export const analyser = new THREE.AudioAnalyser(sound, 32);

const data = analyser.getAverageFrequency();
// console.log(data);



// var analyser = sound.context;
// analyser.fftSize = 2048;



// var data = new Uint8Array(analyser.fftSize / 2);

// analyser.getByteFrequencyData(data);