import * as THREE from 'three'

import cube_vert from "./shaders/cube/vertShader.glsl"
import cube_frag from "./shaders/cube/fragShader.glsl"

import floor_vert from "./shaders/floor/vertShader.glsl"
import floor_frag from "./shaders/floor/fragShader.glsl"

import background_vert from "./shaders/background/vertShader.glsl"
import background_frag from "./shaders/background/fragShader.glsl"

import lines_vert from "./shaders/lines/vertShader.glsl"
import lines_frag from "./shaders/lines/fragShader.glsl"

// console.log("sphere");

import { setup } from "./setup";


let scene = setup.scene;

class TimeUpdater {
    constructor() {
        this.uniforms = [];
    }

    add(mesh) {
        this.uniforms.push(mesh.material.uniforms);
    }

    updateTime(time) {

        this.uniforms.map((uniform, index, array) => {
            uniform.iTime.value = time;
        });
    }
}

export let timeUpdater = new TimeUpdater();


let sphereGeometry = new THREE.SphereGeometry(1,100,100);
let sphereMaterial = new THREE.ShaderMaterial({
    uniforms: {
        iTime: {value: 0},
        color_choice: {value: new THREE.Vector3(1,1,1)},
    },
    vertexShader: cube_vert,
    fragmentShader: cube_frag,
    transparent: false
});

export let sphereMesh = new THREE.Mesh(sphereGeometry,sphereMaterial);
timeUpdater.add(sphereMesh);

sphereMesh.scale.set(0.5,0.5,0.5);
sphereMesh.material.uniforms.color_choice.value = new THREE.Vector3(0.1, 0.4, 0.4);



export var sphereMeshes = [];

scene.add(sphereMesh);
sphereMeshes.push(sphereMesh);
sphereMesh.offset = sphereMesh.position.clone();

var sphereMesh2 = sphereMesh.clone();
sphereMesh2.material = sphereMesh2.material.clone();
sphereMesh2.material.uniforms.color_choice.value = new THREE.Vector3(0.1,0.1,0.4);

sphereMesh2.position.set(1.,1.,0.)
sphereMesh2.offset = sphereMesh2.position.clone();

scene.add(sphereMesh2)
sphereMeshes.push(sphereMesh2);

var sphereMesh3 = sphereMesh.clone();
sphereMesh3.material = sphereMesh3.material.clone();
sphereMesh3.material.uniforms.color_choice.value = new THREE.Vector3(0.3, 0.1, 0.4);

sphereMesh3.position.set(-1., 1., -1.)
sphereMesh3.offset = sphereMesh3.position.clone();

scene.add(sphereMesh3)
sphereMeshes.push(sphereMesh3);

// export let copy = sphereMesh.clone();
// scene.add(copy);

// export let copy2 = sphereMesh.clone();
// scene.add(copy2);

var bg_resolution = new THREE.Vector2(window.innerWidth * window.devicePixelRatio, window.innerHeight * window.devicePixelRatio);

let planeGeometry = new THREE.PlaneGeometry(1,1,100,100);
let planeMaterial = new THREE.ShaderMaterial({
    uniforms: {
        iTime: {value: 0},
        resolution: { value: bg_resolution }
    },
    vertexShader: floor_vert,
    fragmentShader: floor_frag,
    // transparent: true
});

export let planeMesh = new THREE.Mesh(planeGeometry,planeMaterial);



function updateBackgroundResolutionOnResize(width,height){
    // planeMaterial.uniforms.resolution.value = new THREE.Vector2(1,1);
    console.log("resizing background");
    bg_resolution.set(width*window.devicePixelRatio,height*window.devicePixelRatio);
    // bg_resolution.set(width, height);
    
}

setup.onResize.push(updateBackgroundResolutionOnResize);

// scene.add(planeMesh);

planeMesh.position.set(0,-1.5,0.);
planeMesh.scale.set(10,10,1.);
planeMesh.rotation.set(-Math.PI/2.,0,0)

let roomMaterial = new THREE.ShaderMaterial({
    uniforms: {
        iTime: {value: 0},
        resolution: { value: bg_resolution }
    },
    vertexShader: background_vert,
    fragmentShader: background_frag,
    transparent: false,
    side: THREE.DoubleSide,
});

export let roomMesh = new THREE.Mesh(sphereGeometry,roomMaterial);
roomMesh.scale.set(20,20,20);

timeUpdater.add(roomMesh);
scene.add(roomMesh);


let lineGeometry = new THREE.CapsuleGeometry(1,0.05,30,30);
let lineMaterial = new THREE.ShaderMaterial({
    uniforms: {
        iTime: { value: 0 },
        resolution: { value: new THREE.Vector2(window.innerWidth * window.devicePixelRatio, window.innerHeight * window.devicePixelRatio) }
    },
    vertexShader: lines_vert,
    fragmentShader: lines_frag,
    transparent: true,
    side: THREE.DoubleSide,
});

export let lineMesh = new THREE.Mesh(lineGeometry, lineMaterial);
// lineMesh.scale.set(20, 20, 20);
lineMesh.scale.set(0.05,3,0.05);
lineMesh.position.set(3,0,0);
// lineMesh.rotation.set(2, 0, 2);
lineMesh.rotation.set(0, 0, 2);

timeUpdater.add(lineMesh);
scene.add(lineMesh);


let lineMesh1 = lineMesh.clone();
lineMesh1.position.set(0.,0.,-3.);
// lineMesh1.rotation.set(1,1,1);
lineMesh1.rotation.set(0, 3, 2);
scene.add(lineMesh1);
timeUpdater.add(lineMesh1);


let lineMesh2 = lineMesh.clone();
lineMesh2.position.set(-3., 0., 0.);
// lineMesh2.rotation.set(0, 1, 1);
lineMesh2.rotation.set(2, 0, 2);
scene.add(lineMesh2);
timeUpdater.add(lineMesh2);

let lineMesh3 = lineMesh.clone();
lineMesh3.position.set(-0.7, 0., 2.);
// lineMesh3.rotation.set(1, 1, 1);
// lineMesh3.rotation.set(2, 0, 1);
scene.add(lineMesh3);
timeUpdater.add(lineMesh3);

let lineMesh4 = lineMesh.clone();
lineMesh4.position.set(0.5, 0., 2.);
// lineMesh4.rotation.set(2, 0, 1);
scene.add(lineMesh4);
timeUpdater.add(lineMesh4);

// let lineMesh5 = lineMesh.clone();
// lineMesh5.position.set(-4.7, 0., -12.);
// lineMesh5.rotation.set(1, 1, 1);
// scene.add(lineMesh5);
// timeUpdater.add(lineMesh5);

// let lineMesh6 = lineMesh.clone();
// lineMesh6.position.set(7.6, 0., -10.);
// lineMesh6.rotation.set(2, 0, 1);
// scene.add(lineMesh6);
// timeUpdater.add(lineMesh6);



console.log(lineMesh4);


