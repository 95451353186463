import './style.css'
import * as THREE from 'three'
// import * as dat from 'dat.gui'
// import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
// import vertShader from "./shaders/vertShader.glsl"
// import fragShader from "./shaders/fragShader.glsl"
import {sphereMesh,copy,copy2,roomMesh,planeMesh, timeUpdater,sphereMeshes} from "./sphere.js"
import {sound,analyser} from "./sound.js"

import {setup,} from "./setup"


function main() {

// BASIC SETUP

var {scene,camera,renderer,cameraControler} = setup;

// RENDER LOOP

// camera.position.set(0.,2.,8.);
// camera.lookAt(0,0,0);

var up = new THREE.Vector3(1.,0.,1.);

var target = new THREE.Vector3(1.,0.,0.);

// import gsap for nice animation?

// var first = true;
var index = 0.;
var moving = false;
const clock = new THREE.Clock();

function render(time)
{   
    // if(index<100){

    // }

//    console.log(analyser.getAverageFrequency());

    timeUpdater.updateTime(time*0.001);

    const delta = clock.getDelta();
    moving = cameraControler.update(delta);
    //is the matrix getting to the vertex shader???

    // copy.position.set(3., Math.cos(time * 0.001) * 0.1+1., -3.);

    // copy.rotation.set(time * 0.0001 + 12522.124, time * 0.00015- 122.2323 , 0.);

    // copy2.position.set(-3., Math.cos(time * 0.001) * 0.1 + .2, -3.);

    // copy2.rotation.set(time * 0.0001 + 12522.124, time * 0.00005 - 122.2323, 0.);

    // console.log(target);

    function smoothstep(near,far,x){
        if (x < near)
            return 0;

        if (x >= far)
            return 1;

        // Scale/bias into [0..1] range
        x = (x - near) / (far - near);

        return x * x * (3 - 2 * x);
    }

    if(index==0){
        sphereMeshes.forEach((m)=>{
            m.position.set(0, -10, 0);
        })
        // sphereMesh.position.set(0,-10,0);
        index += 1.;
    } else {
        sphereMeshes.forEach((m)=>{
            m.position.set(0., Math.cos(time * 0.001 + m.offset.x) * 0.25, 0.);
            m.position.add(m.offset);
        })
        // + 0 * (- 5. + smoothstep(0., 5., time / 1000) * 15. - 10.)
        // sphereMesh.position.set(0., Math.cos(time * 0.001) * 0.25, 0.);
    }
    // 


    // sphereMesh.lookAt(target);s
    sphereMeshes.forEach((m) => {


        m.rotation.set(time * 0.0001 + time * 0.00021 * m.offset.x+m.offset.x, time * 0.00025 + time * 0.00013*m.offset.x + m.offset.x+2, 0.);
        // m.rotation.
    })
    // sphereMesh.rotation.set(time*0.0001,time*0.00025,0.);
    // sphereMesh.matrixWorldNeedsUpdate = true;
    // sphereMesh.rotateZ(0.1);

    // console.log(sphereMesh);
    // sphereMesh.matrixWorldNeedsUpdate = true;
    // console.log(sphereMesh);

    renderer.render(scene,camera);
    requestAnimationFrame ( render );
}

requestAnimationFrame ( render );

}

main();




